<template>
  <div
      class="bg-img-login"
      :style="{backgroundImage:`url(${sideImg}`}"
  >


    <div class="logo-vacina">
      <img :src="logoImg" alt="">
    </div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      sideImg: require('@/assets/images/login/img-login.png'),
      logoImg: require('@/assets/images/login/vacinasesi.png'),
    }
  },
}
</script>
